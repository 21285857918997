<template>
  <v-container fluid>
    <v-row class="ma-3" wrap>
      <!-- Nome -->
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          label="Nome"
          ref="nome"
          placeholder="Escreva o nome do cargo"
          required
          outlined
          dense
          v-model.trim="cargo.nome"
          data-vv-as="nome"
          persistent-hint
          :hint="errors.first('adm_cargos.nome')"
          :error="errors.collect('adm_cargos.nome').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_cargos"
          data-vv-validate-on="change"
          id="nome"
          name="nome"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          label="Salário"
          ref="nome"
          placeholder="Digite o salário"
          required
          outlined
          dense
          v-model="cargo.salario"
          data-vv-as="salário"
          persistent-hint
          :hint="errors.first('adm_cargos.salario')"
          :error="errors.collect('adm_cargos.salario').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_cargos"
          data-vv-validate-on="change"
          id="salario"
          name="salario"
          v-mask="[
            '###,##',
            '#.###,##',
            '##.###,##',
            '###.###,##',
            '#.###.###,##',
            '##.###.###,##',
            '###.###.###,##',
            '#.###.###.###,##',
          ]"
        ></v-text-field>
      </v-col>
      <!-- Filial -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          v-model="cargo.id_filial"
          :items="filiais"
          :loading="loadings.loadingFilial"
          label="Filial"
          ref="filial"
          placeholder="Selecione uma filial da empresa"
          item-text="nome"
          item-value="id"
          dense
          outlined
          clearable
        ></v-select>
      </v-col>
      <!-- Setor -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          v-model="cargo.id_setor"
          :items="setores"
          :loading="loadings.loadingSetor"
          label="Setor"
          ref="setor"
          placeholder="Selecione um setor"
          item-text="nome"
          item-value="id"
          dense
          outlined
          data-vv-as="setor"
          persistent-hint
          :hint="errors.first('adm_cargos.setor')"
          :error="errors.collect('adm_cargos.setor').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_cargos"
          data-vv-validate-on="change"
          id="setor"
          name="setor"
        ></v-select>
      </v-col>
      <!-- Descrição -->
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          label="Descrição"
          ref="descricao"
          outlined
          dense
          v-model.trim="cargo.descricao"
          data-vv-as="descrição"
          persistent-hint
          :hint="errors.first('adm_cargos.descricao')"
          :error="errors.collect('adm_cargos.descricao').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_cargos"
          data-vv-validate-on="change"
          id="descricao"
          name="descricao"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CargosForm",
  inject: ["$validator"],
  data() {
    return {
      loadings: {
        loadingFilial: true,
        loadingSetor: true,
      },
    };
  },
  async created() {
    const dataFilial = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };
    const dataSetor = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };

    await this.$store.dispatch("filiais/findByColumns", dataFilial);
    this.loadings.loadingFilial = false;

    await this.$store.dispatch("setores/findByColumns", dataSetor);
    this.loadings.loadingSetor = false;
  },
  computed: {
    cargo: {
      get() {
        return this.$store.getters["cargos/getCargo"];
      },
      set(value) {
        return this.$store.dispatch("cargos/setCargo", value);
      },
    },
    filiais: {
      get() {
        return this.$store.getters["filiais/getFiliais"];
      },
    },
    setores: {
      get() {
        return this.$store.getters["setores/getSetores"];
      },
    },
  },
};
</script>

<style></style>
